import { CreatibutorsField } from '../CreatibutorsField/CreatibutorsField';
import { i18next } from "@translations/invenio_rdm_records/i18next";
import React, { Component } from "react";

export class AuthorsField extends CreatibutorsField {
    constructor(props) {
        super(props);
    }

    render() {
        const {label, labelIcon, fieldPath, roleOptions, schema, autocompleteNames, required, modal, addButtonLabel} = this.props;
        const modalNew = { ...modal, addLabel: "Add author", editLabel: "Edit author" };
        return <CreatibutorsField   label={"Authors"}
                                    labelIcon="user"
                                    fieldPath="metadata.creators"
                                    roleOptions={roleOptions}
                                    schema="creators"
                                    addButtonLabel="Add author"
                                    modal={modalNew}
                                    autocompleteNames={autocompleteNames}
                                    required/>
    }
}